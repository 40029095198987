<template>
  <CModal id="uploadFileModal" :show.sync="isOpenModal" :closeOnBackdrop="false" centered>
    <template #header>
      <label>{{$t('UPLOAD_MODAL_COMPONENT_LABEL_TITLE')}}</label>
      <CButton @click="closeModal($event, false)">
        <CIcon name="cil-x" />
      </CButton>
    </template>
    <div class="wrap-upload-content" v-if="isOpenModal">
      <div class="group-btn-choose-file-csv">
        <input class="btn-choose-file" type="file" ref="file" @change="chooseFileCSV" />
        <div class="wrap-choose-file">
          <CIcon name="cil-file" />
          <label>
            {{fileChosenInfo.name}}
            <small
              v-if="fileChosenInfo.size"
            >({{formatFileSize(fileChosenInfo.size)}})</small>
          </label>
        </div>
        <div class="wrap-err-message">
          <p v-if="isInvalidUploadCsv.isInvalid">{{isInvalidUploadCsv.errMsg}}</p>
        </div>
      </div>
      <CButton @click="uploadCSV()">{{$t('UPLOAD_MODAL_COMPONENT_BUTTON_UPLOAD')}}</CButton>
    </div>
  </CModal>
</template>

<script>
import responseHelper from '@/utils/response-helper';
import endpoints from '@/constants/endpoints';

export default {
  name: 'UploadCSVModal',
  data() {
    return {
      fileChosenInfo: this.fileInfoDefault(),
      fileCSV: null,
      isInvalidUploadCsv: {
        errMsg: '',
        isInvalid: false,
      },
    };
  },
  props: {
    isOpenModal: {
      type: Boolean,
      default: false,
    },
    postTo: {
      type: String,
      default: '',
    },
    keyTxIdName: {
      type: String,
      default: '',
    },
  },
  methods: {
    fileInfoDefault() {
      return {
        name: this.$t('UPLOAD_MODAL_COMPONENT_LABEL_CHOOSE_FILE'),
        size: '',
        type: '',
      };
    },
    chooseFileCSV(event) {
      this.fileCSV = this.$refs.file.files[0];
      if (this.fileCSV) {
        this.fileChosenInfo.name = this.fileCSV.name;
        this.fileChosenInfo.size = this.fileCSV.size;
      } else {
        this.fileChosenInfo = this.fileInfoDefault();
      }
      this.isInvalidUploadFile(this.fileChosenInfo);
    },
    isInvalidUploadFile(el) {
      const isCsvType = el.name.toLowerCase().endsWith('.csv');
      if (!isCsvType) {
        this.isInvalidUploadCsv = {
          errMsg: this.$t('UPLOAD_MODAL_COMPONENT_NOTIFY_ERROR_MESSAGE_WRONG_TYPE'),
          isInvalid: true,
        };
        return;
      } else if (el.size > 1024 * 1024 * 2) {
        this.isInvalidUploadCsv = {
          errMsg: this.$t('UPLOAD_MODAL_COMPONENT_NOTIFY_ERROR_MESSAGE_WRONG_SIZE'),
          isInvalid: true,
        };
        return;
      } else {
        this.isInvalidUploadCsv = {
          errMsg: '',
          isInvalid: false,
        };
      }
    },
    async uploadCSV() {
      if (!this.fileCSV || this.isInvalidUploadCsv.isInvalid) {
        return;
      }
      try {
        var formData = new FormData();
        formData.set(this.keyTxIdName, this.fileCSV);
        const headers = {
          'Content-Type': 'multipart/form-data;',
        };
        const res = await this.$http.post(this.postTo, formData, { headers });
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('UPLOAD_MODAL_COMPONENT_TITLE_NOTIFY_SUCCESS'),
          text: `${this.fileChosenInfo.name}  ${this.$t('UPLOAD_MODAL_COMPONENT_NOTIFY_UPLOAD_CSV_SUCCESS_MESSAGE')}`,
        });
        this.closeModal(null, true);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('UPLOAD_MODAL_COMPONENT_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    formatFileSize(size) {
      const sizeUnit = ['Bytes', 'KB', 'MB', 'GB'];
      var i = 0;
      while (size > 900) {
        size /= 1024;
        i++;
      }
      var exactSize = Math.round(size * 100) / 100 + ' ' + sizeUnit[i];
      return exactSize;
    },
    closeModal(evt, isFreshData) {
      this.fileChosenInfo = this.fileInfoDefault();
      this.fileCSV = null;
      this.isInvalidUploadCsv.errMsg = '';
      this.$emit('close-modal', !!isFreshData);
    },
  },
};
</script>

<style lang="scss">
#uploadFileModal {
  .modal-header {
    border: none;
    label {
      font-size: 18px;
      font-weight: 700;
      color: #657187;
    }
  }
  .modal-body {
    display: flex;
    padding-top: 0;
    .wrap-upload-content {
      display: flex;
      flex: 0 0 100%;
      justify-content: space-around;
      .group-btn-choose-file-csv {
        position: relative;
        width: 50%;
        padding-right: 10px;
        input {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
        }
        .wrap-choose-file {
          display: flex;
          flex: 0 0 100%;
          min-height: 35px;
          align-items: center;
          border-radius: 4px;
          justify-content: center;
          padding: 0 5px;
          background: #dadada;
          svg {
            font-size: 18px;
            width: 18px;
            flex: 0 0 18px;
            height: 18px;
          }
          label {
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: #3a4a7f;
            margin: 0;
            padding: 0 5px;
          }
        }
        .wrap-err-message {
          p {
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: #ff0000;
            margin: 5px 0 0;
          }
        }
      }
      button {
        width: 40%;
        height: 35px;
        border: none;
        border-radius: 4px;
        background-color: #3b4b7e;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        color: #fff;
      }
    }
  }
  .modal-footer {
    display: none;
  }
}
</style>
